import React, { useEffect, useState } from 'react';
import { getAllNotes } from '../services/noteService';
import { Note } from '../types/Note';

// Added NoteListProps interface to accept the notesChanged prop
interface NoteListProps {
  notesChanged: boolean; // Prop to re-fetch notes when a note is added
}

// Modified NoteList component to accept the notesChanged prop
const NoteList: React.FC<NoteListProps> = ({ notesChanged }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchNotes = async () => {
    try {
      const notes = await getAllNotes();
      console.log('Fetched notes:', notes); // Log the fetched notes
      if (Array.isArray(notes)) {
        setNotes(notes);
      } else {
        setError('Failed to fetch notes');
      }
    } catch (err) {
      console.error('Error fetching notes:', err);
      setError('Failed to fetch notes');
    } finally {
      setLoading(false);
    }
  };
  // Modified useEffect to listen to notesChanged prop and re-fetch notes when it changes
  useEffect(() => {
    fetchNotes();
  }, [notesChanged]); // Trigger re-fetch when notesChanged toggles

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h2>All Notes</h2>
      <ul>
        {notes.map(note => (
          <li key={note.id}>
            <p>{note.text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NoteList;
