// src/services/lakeService.ts
export const fetchLakes = async (): Promise<string[]> => {
    const response = await fetch('/api/lakes/all');
    if (!response.ok) {
      throw new Error("Failed to fetch lakes");
    }
    return response.json();
  };
  
  export const fetchData = async (lake: string, startDate: string, endDate: string) => {
    try {
      const url = new URL('/api/data', window.location.origin);
      url.searchParams.append('lake', lake);
      url.searchParams.append('startDate', startDate);
      url.searchParams.append('endDate', endDate);
  
      const response = await fetch(url.toString());
  
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };
  
