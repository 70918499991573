// src/components/DisplayData.tsx

import React, { useEffect, useState } from 'react';
import { fetchData } from '../services/lakeService';

interface DataRow {
  last_updated: string;
  lake: string;
  station: string;
  sdate: string;
  ph: number;
}

const DisplayData: React.FC<{ selectedLake: string, startDate: string, endDate: string }> = ({ selectedLake, startDate, endDate }) => {
  const [data, setData] = useState<DataRow[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const loadData = async () => {
      if (selectedLake && startDate && endDate) {
        try {
          const fetchedData = await fetchData(selectedLake, startDate, endDate);
          setData(fetchedData);
        } catch (err) {
          setError("Failed to load data. Please try again.");
          console.error(err);
        }
      }
    };
    loadData();
  }, [selectedLake, startDate, endDate]);

  return (
    <div>
      <h2>Inland Lakes Drinking Water Quality Data</h2>
      <p>This dataset tracks the chemistry and water quality at various inlet points of inland lakes for drinking water safety purposes.</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      <table>
        <thead>
          <tr>
            <th>Lake</th>
            <th>Station</th>
            <th>Sample Date</th>
            <th>pH</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.lake}</td>
              <td>{row.station}</td>
              <td>{row.sdate}</td>
              <td>{row.ph}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className="coming-soon"><em>Data visualization feature coming soon!</em></p>
    </div>
  );
};

export default DisplayData;
