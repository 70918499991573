import React, { useEffect, useState } from 'react';
import { fetchLakes } from '../services/lakeService';
import DisplayData from './DisplayData';

const SelectData: React.FC = () => {
  const [lakes, setLakes] = useState<string[]>([]);
  const [selectedLake, setSelectedLake] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const loadLakes = async () => {
      try {
        const data = await fetchLakes();
        setLakes(data);
      } catch (error) {
        console.error("Error fetching lakes:", error);
      }
    };

    loadLakes();
  }, []);

  const handleLakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLake(event.target.value);
    setStartDate(''); // Reset dates when lake changes
    setEndDate('');
    setErrorMessage(''); 
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);

    if (endDate && newStartDate > endDate) {
      setErrorMessage('Start date cannot be after end date.');
    } else {
      setErrorMessage('');
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);

    if (startDate && startDate > newEndDate) {
      setErrorMessage('End date cannot be before start date.');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <div>
      <h2>Select an Inland Lake</h2>
      <label htmlFor="lake-select" className="section-label">Select Lake:</label>
      <select
        id="lake-select"
        value={selectedLake}
        onChange={handleLakeChange}
        className="dropdown-select"
      >
        <option value="">-- Select Lake --</option>
        {lakes.map((lake, index) => (
          <option key={index} value={lake}>
            {lake}
          </option>
        ))}
      </select>

      <h2>Select Date Range</h2>
      <div className="date-range-container">
        <label htmlFor="start-date" className="section-label">Start:</label>
        <input
          type="date"
          id="start-date"
          value={startDate}
          onChange={handleStartDateChange}
          className="date-input"
          disabled={!selectedLake} // Disable if no lake is selected
        />

        <label htmlFor="end-date" className="section-label" style={{ marginLeft: '20px' }}>End:</label>
        <input
          type="date"
          id="end-date"
          value={endDate}
          onChange={handleEndDateChange}
          className="date-input"
          disabled={!selectedLake} // Disable if no lake is selected
        />
      </div>

      {errorMessage && (
        <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>
      )}


<div>
    {/* Existing selection UI */}
    
    {selectedLake && startDate && endDate && (
      <DisplayData selectedLake={selectedLake} startDate={startDate} endDate={endDate} />
    )}
  </div>
    </div>
  );
};

export default SelectData;
