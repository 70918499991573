import React, { useState, useEffect } from "react";
import AddNote from "./components/AddNote";
import NoteList from "./components/NoteList";

const App: React.FC = () => {
  const [currentDate, setCurrentDate] = useState<string>("");
  const [notesChanged, setNotesChanged] = useState(false);

  const handleNoteAdded = () => {
    // This function will be passed down to AddNote and called when a note is added
    setNotesChanged(!notesChanged); // Toggle the state to trigger refresh
  };

  // Function to get current date in desired format
  const getCurrentDate = (): string => {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return today.toLocaleDateString(undefined, options);
  };

  // Set the current date when the component mounts
  useEffect(() => {
    setCurrentDate(getCurrentDate());
  }, []);

  return (
    <div className="App">
      <h1>TODO Notes Application</h1>
      <p>Date: {currentDate}</p>
      <p>
        To add a new note, please enter your text in the input box below and
        click &quot;Add Note.&quot; Your note will be saved and appear under
        &quot;All Notes.&quot;
      </p>
      <AddNote onNoteAdded={handleNoteAdded} />
      <NoteList notesChanged={notesChanged} />
    </div>
  );
};

export default App;
