import React, { useState } from "react";
import { addNote } from "../services/noteService";
import { Note } from "../types/Note";

// Added the AddNoteProps interface to define the expected prop
interface AddNoteProps {
  onNoteAdded: () => void; // This prop is a function to be called when a note is added
}

// Modified AddNote component to accept AddNoteProps
const AddNote: React.FC<AddNoteProps> = ({ onNoteAdded }) => {
  const [text, setText] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newNote: Omit<Note, "id"> = { text };
    await addNote(newNote);
    setText("");
    onNoteAdded(); // Call the onNoteAdded prop function to trigger a refresh after adding a note
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Text</label>
        <textarea value={text} onChange={(e) => setText(e.target.value)} />
      </div>
      <button type="submit">Add Note</button>
    </form>
  );
};

export default AddNote;
